import { MenuOutlined } from '@ant-design/icons'
import { Button, Drawer, Image } from 'antd'
import { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { MENU_CONFIGS } from './config'
import { Link } from 'react-router-dom'
import { useConfig } from 'services/swr/settings'
// import Button from 'components/Button'

function Header() {
  const location = useLocation()
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
  const toggleMobileMenu = () => setIsOpenMobileMenu((prev) => !prev)

  return (
    <>
      <WrapperHeader>
        <div className='container body'>
          <div className='menu'>
            <MenuLeft>
              <NavLink to="/">
                <Image preview={false} src='/images/logo/main-logo.svg' className='w-10' />
              </NavLink>

              <ul className='content-list'>
                <li className='text-lg font-bold leading-[1]'>Alo</li>
                <li className='text-lg font-bold leading-[1]'>Wallet</li>
              </ul>
            </MenuLeft>

            {MenuRight(location.pathname, true)}

            <div className="flex items-center gap-3">
              <Button color="primary" variant="outlined">
                Join communicate
              </Button>
              <MenuOutlined className='mobile-menu' onClick={toggleMobileMenu} />
            </div>
          </div>
        </div>
      </WrapperHeader>

      <Drawer
        title={<Image preview={false} src='/images/logo/main-logo.svg' className='w-10' />}
        onClose={toggleMobileMenu}
        open={isOpenMobileMenu}
        width={300}
      >
        {MenuRight(location.pathname)}
      </Drawer>
    </>
  )
}
export default Header

const MenuRight = (pathname, isPC = false) => {
  const location = useLocation()

  const { data: config } = useConfig({
    revalidateIfStale: false,
  })

  const currentPath = location.pathname

  return (
    <MenuRightWrapper className={isPC ? 'pc' : ''}>
      {MENU_CONFIGS?.map((menu) => {
        const active = menu?.actives?.includes(currentPath)

        return (
          <li key={menu?.label} className={active ? 'menu-item active' : 'menu-item'}>
            {menu?.external ? (
              <a href={menu?.href === '/ban-cam-ket.pdf' ? config?.pdfCommitment : menu?.href} target='_blank' rel='noreferrer'>
                {menu?.label}
              </a>
            ) : (
              <Link to={menu?.href as string}>
                {menu?.label}
              </Link>
            )}
          </li>
        )
      })}
    </MenuRightWrapper>
  )
}

const WrapperHeader = styled.header`
  width: 100%;
  background: #fff;

  .body {
    padding: 15px;

    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .mobile-menu {
        display: block;

        svg {
          width: 24px;
          height: 24px;
        }

        ${({ theme }) => theme.mediaQueries.md} {
          display: none;
        }
      }
    }
  }
`

const MenuRightWrapper = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;

  width: 100%;
  list-style: none;
  flex: 1;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    align-items: center;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    gap: 24px;
  }

  &.pc {
    display: none;

    ${({ theme }) => theme.mediaQueries.md} {
      display: flex;
    }
  }

  .menu-item {
    font-weight: 600;

    a {
      text-transform: uppercase;
      transition: all 0.2s ease-in;
    }

    &:hover a {
      color: var(--primary-color);
    }

    &.active {
      color: var(--primary-color);
    }
  }
`

const MenuLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  > a {
    flex-shrink: 0;
  }

  .menu-logo {
    width: 100%;
    max-width: 70px;
  }

  .content-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

