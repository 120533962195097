import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  :root {
    --primary-color: #3361f4;

    --button-primary-color: #008848;
    --button-primary-color-hover: #008848CC;
  }

  * {
    font-family: Nunito Sans;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
  } 
  *, 
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    color: #323338; 
    line-height: 1.3;
    font-size: 16px;
    
    @media (max-width: 576px) {
      width: 100% !important; 
    } 

    & > iframe {
      display: none; 
    }

    img {
      height: auto;
      max-width: 100%;

      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .public-layout{
    overflow: hidden;
    min-height: 100vh;
    background: #fff;
  }

  .container{
    width: 100%;
    max-width: 1440px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }
`

export default GlobalStyle
