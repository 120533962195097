import styled from 'styled-components'

const RoadmapPage = () => {
	return (
		<Wrapper className='container'>
			<section className="timeline__cover">
				<div className="timeline__title">
					<h2>Roadmap</h2>
				</div>
				<div className="timeline">
					<ul>
						<li>
							<div className="content">
								<div className="badge">2021: Preparing</div>
								<h3>Q3</h3>
								<p>
									Start project and open private sales for token
									<br />
									Deploy smart contract of token and publicize the project to the community
									<br />
									Public Fundraising: IEO, IDO (20 July)
								</p>
							</div>
						</li>

						<li>
							<div className="content">
								<h3>Q3 - Q4</h3>
								<p>
									Building Alo Wallet application on Android and iOS
									<br />
									Building a community with more than 20,000 members: Alo Wallet Global (Telegram), Alo Wallet Vietnam (Facebook)
								</p>
							</div>
						</li>
						<li>
							<div className="content">
								<h3>Q4</h3>
								<p>
									Soft Launching
									<br />
									An open beta of the Alo Wallet will be available on Google Play & Apple Store with basic features such as Store assets; Swap (trading with DEXes) & Investing
								</p>
							</div>
						</li>

						<li>
							<div className="content">
								<div className="badge">2022: Launching</div>
								<h3>Q1</h3>
								<p>
									Official Launching
									<br />
									A complete version will be launch with many features as Store (with over 500 tokens); Swap; Investing
								</p>
							</div>
						</li>

						{/* <li>
							<div className="content">



							</div>
						</li> */}

						<li>
							<div className="content">
								<h3>Q2</h3>
								<p>Reach 100,000 active users, most popular come from Vietnam</p>
							</div>
						</li>
						<li>
							<div className="content">
								<h3>Q3</h3>
								<p>Alo Wallet app will be published earning features such as Farming, Staking</p>
							</div>
						</li>
						<li>
							<div className="content">

								<h3>Q4</h3>
								<p>Alo Wallet app will be published Credit Line, Lending features</p>
							</div>
						</li>
						<li>
							<div className="content">
								<h3>End of the year</h3>
								<p>Reaching more than 500,000 users</p>
							</div>
						</li>

						<li>
							<div className="content">
								<div className="badge">2023: Growing</div>
								<h3></h3>
								<p>
									Publishing the Alo Decentralized Finance Ecosystem (RDFE)
									<br />
									Alo Wallet reaches over 2 million global users at the end of the year. And it also completes the ecosystem-based Alo Wallet and Alo Token
								</p>
							</div>
						</li>

						<li>
							<div className="content">
								<div className="badge">2024: Growing</div>
								<h3></h3>
								<p>
									Alo Wallet reaches over 5 million global users at the end of the year
								</p>
							</div>
						</li>

						<li>
							<div className="content">
								<div className="badge">2025: Growing</div>
								<h3></h3>
								<p>
									Alo Wallet reaches over 10 million global users at the end of the year
								</p>
							</div>
						</li>
					</ul>
				</div>
			</section>
		</Wrapper>
	)
}

export default RoadmapPage

const Wrapper = styled.div`
	.copyright__part{
	padding: 10px 20px;
	background: #000000;
	text-align: center;
	font-weight: 300;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
}

.copyright__part a{
	font-size: 14px;
	text-decoration: none;
}

.timeline__cover{
    padding: 100px 0;
}
.timeline__cover .timeline__title{
    text-align: center;
    padding: 0 16px;
}
.timeline__cover .timeline__title h2{
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    margin-bottom: 16px;
	text-align: left;
}
.timeline__cover .timeline__title p{
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
    color: #959595;
}

.timeline__cover .timeline{
    padding-top: 64px;
}
.timeline__cover .timeline ul{
    padding-top: 100px;
}
.timeline__cover .timeline ul li {
	background: #9a9a9a;
	position: relative;
	margin: 0 auto;
	width: 2px;
	margin-bottom: 48px;
	list-style-type: none;
}

.timeline__cover .timeline ul li:last-child {
	padding-bottom: 7px;
    width: 0;
    height: 0;
}

.timeline__cover .timeline ul li:before {
	content: '';
	background: #3361f4;
	position: absolute;
	left: 50%;
	top: -32px;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	width: 16px;
	height: 16px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.timeline__cover .timeline ul li .hidden {
	opacity: 0;
}

.timeline__cover .timeline ul li .content {
	position: relative;
	top: -100px;
	width: 320px;
}

.timeline__cover .timeline ul li .content .badge{
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    display: inline-block;
    padding: 6px 24px;
    background: #3361f4;
    border-radius: 1000px;
}

.timeline__cover .timeline ul li .content h3{
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin: 16px 0 8px;
}

.timeline__cover .timeline ul li .content p{
	color: #858792;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.timeline__cover .timeline ul li:nth-child(odd) .content {
	left: 70px;
}

.timeline__cover .timeline ul li:nth-child(odd) .content:before {
	left: -38px;
}

.timeline__cover .timeline ul li:nth-child(even) .content {
	left: calc(-289px - 70px);
}

.timeline__cover .timeline ul li:nth-child(even) .content:before {
	right: -38px;
}

/* Media Queries */
@media screen and (max-width: 1020px) {
	.timeline__cover .timeline ul li .content {
		width: 41vw;
	}

	.timeline__cover .timeline ul li:nth-child(even) .content {
		left: calc(-41vw - 45px);
	}
}

@media screen and (max-width: 700px) {
    .timeline__cover {
        padding: 32px 0;
    }
    .timeline__cover .timeline__title {
        text-align: left;
    }
    .timeline__cover .timeline {
        padding-top: 32px;
    }
    .timeline__cover .timeline ul li {
        margin-left: 48px !important;
    }
    .timeline__cover .timeline__title h2 {
        font-size: 32px;
        line-height: 44px;
        letter-spacing: 0.08px;
    }
	.timeline__cover .timeline ul li {
		margin-left: 20px;
	}

	.timeline__cover .timeline ul li .content {
		width: calc(100vw - 120px);
        transition: .2s;
	}

    .timeline__cover .timeline ul li .content p {
        min-height: 110px;
    }

	.timeline__cover .timeline ul li:nth-child(even) .content {
		left: 38px;
	}

	.timeline__cover .timeline ul li:nth-child(even) .content:before {
		left: -33px;
	}
    .timeline__cover .timeline ul li:nth-child(odd) .content {
        left: 38px;
    }
}
`