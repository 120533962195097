import { ConfigProvider } from 'antd'
import React, { ReactNode } from 'react'
import SwrProvider from 'services/swr/SwrProvider'
import { LanguageProvider } from 'translations'
import { ThemeContextProvider } from './ThemeContext'

const Providers: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ConfigProvider>
      <ThemeContextProvider>
        <LanguageProvider>
          <SwrProvider>{children}</SwrProvider>
        </LanguageProvider>
      </ThemeContextProvider>
    </ConfigProvider>
  )
}

export default Providers
