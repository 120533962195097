import { Image } from 'antd'

const Banner = () => {
	return (
		<div className='grid sm:grid-cols-2 sm:gap-0 gap-6 container lg:pt-24 sm:pt-14 pt-10'>
			<div className="flex flex-col justify-center lg:gap-6 gap-4">
				<h1 className="text-primary font-extrabold lg:text-5xl md:text-4xl text-3xl">
					Investing in DeFi
					<br />
					<span className='text-[#313339]'>for Everyone</span>
				</h1>

				<p className="text-[#858792] lg:text-xl text-base max-w-[550px]">
					ALO Wallet is a non-custodial wallet that helps you store, invest and manage cryptocurrencies from one place with the best user experience.
				</p>

				<div className="flex items-center gap-4">
					<Image preview={false} src='/images/logo/ios-download.png' className='max-w-[170px]' />
					<Image preview={false} src='/images/logo/android-download.png' className='max-w-[170px]' />
				</div>
			</div>
			<div>
				<Image preview={false} src='/images/home/banner.png' className='max-h-[700px]' />
			</div>
		</div>
	)
}

export default Banner