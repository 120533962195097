import {
  HomeOutlined,
  FormOutlined,
  SearchOutlined,
  IdcardOutlined,
  SketchOutlined,
  UserAddOutlined,
  ProductOutlined,
  SettingOutlined,
} from '@ant-design/icons'

export interface IMenuSubEntry {
  label: string
  href: string
  target?: string
  calloutClass?: string
  icon?: any
  iconLight?: string
}

export interface IMenuEntry {
  label: string
  icon: any
  items?: IMenuSubEntry[]
  href?: string
  att?: string
  target?: string
  calloutClass?: string
  initialOpenState?: boolean
  external?: boolean
  actives: string[]
}

export const MENU_CONFIGS: IMenuEntry[] = [
  {
    label: 'Home',
    icon: HomeOutlined,
    href: '/',
    actives: ['/'],
  },
  {
    label: 'Roadmap',
    icon: HomeOutlined,
    href: '/roadmap',
    actives: ['/roadmap'],
  },
  {
    label: 'About us',
    icon: HomeOutlined,
    href: '/about-us',
    actives: ['/about-us'],
  },
  {
    label: 'Blog',
    icon: HomeOutlined,
    href: '/blog',
    actives: ['/blog'],
  },
  {
    label: 'Help center',
    icon: HomeOutlined,
    href: '/help-center',
    actives: ['/help-center'],
  },
]
