import { Image } from "antd"

const DATA = [
	{
		desc: "CertiK is the security-focused blockchain protocols and DeFi projects.",
		image: "/images/home/safe-1.png"
	},
	{
		desc: "CyStack is a leading company in providing security services.",
		image: "/images/home/safe-2.png"
	},
	{
		desc: "Verichains provides high-quality smart contract security audit services.",
		image: "/images/home/safe-3.png"
	},
]

const Secure = () => {
	return (
		<div className="container pt-16 xl:pb-36 pb-16">
			<h2 className="font-extrabold lg:text-5xl md:text-4xl text-3xl md:mb-10 mb-8">
				Safe & Secure
			</h2>

			<div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
				{DATA.map(item => <CardItem data={item} key={item.image} />)}
			</div>
		</div>
	)
}

export default Secure

const CardItem = ({ data }) => (
	<div className="lg:py-10 lg:px-8 py-6 px-4 flex flex-col gap-3 border-solid border-[1px] rounded border-[#ccc]">
		<p className="text-primary font-bold">Audited by</p>
		<div className="mb-4">
			<Image preview={false} src={data.image} className='max-w-[210px]' />
		</div>
		<p className="text-[#858792] md:text-base text-sm ">
			{data.desc}
		</p>
	</div>
)