import Banner from "./components/Banner"
import Fade from 'react-reveal/Fade'
import Features from "./components/Features"
import Earn from "./components/Earn"
import Secure from "./components/Secure"

const HomePage = () => {
  return (
    <div className=''>
      <Fade>
        <Banner />
      </Fade>
      <Fade>
        <Features />
      </Fade>
      <Fade>
        <Earn />
      </Fade>
      <Fade>
        <Secure />
      </Fade>
    </div>
  )
}

export default HomePage
