import {
  BarChartOutlined,
  CheckOutlined,
  FieldTimeOutlined,
  HistoryOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  NodeExpandOutlined,
  QuestionCircleOutlined,
  ReconciliationOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Input, Menu } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ROUTER from 'services/router'
import styled from 'styled-components'

const { Search } = Input

const heightHeader = '70px'

const WrapperHeaderPrivate = styled.div``

const HeaderPrivate = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [searchValue, setSearchValue] = useState('')
  const [collapsed] = useState(false)
  const [active, setActiveMenu] = useState<string>(ROUTER.adminHome)
  const [open, setOpenMenu] = useState<boolean>(false)

  useEffect(() => {
    if (location.pathname) {
      setActiveMenu(location.pathname)
    }
  }, [location.pathname])

  const handelLink = (link: string) => {
    navigate(link)
    setOpenMenu(false)
  }

  const onSearch = useCallback(
    (e) => {
      navigate(`/tim-kiem-don-van?keyword=${e}`)
    },
    [navigate]
  )

  useEffect(() => {
    if (location?.pathname === '/theo-doi-don-van') return
    setSearchValue('')
  }, [location?.pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location?.pathname])

  return (
    <WrapperHeaderPrivate>
      <>
        <WrapperHeader>
          <div className='content-header'>
            <div className='wrap-search'>
              <Search
                allowClear
                value={searchValue}
                style={{ maxWidth: 500, width: '100%' }}
                placeholder='Nhập mã đơn vận, tên người nhận, số điện thoại người nhận...'
                onSearch={onSearch}
                onChange={(e) => setSearchValue(e?.target?.value)}
                onPressEnter={(e: any) => onSearch(e?.target?.value)}
              />
            </div>

            <UserMenu>
              <UserWrapper>
                <Link to={ROUTER.profile}>
                  <p>
                    <UserOutlined className='user-icon' />
                  </p>
                </Link>
              </UserWrapper>

              <button className='btn-open-menu' type='button' onClick={() => setOpenMenu((prev) => !prev)}>
                {open ? <MenuUnfoldOutlined style={{ fontSize: '26px' }} /> : <MenuFoldOutlined style={{ fontSize: '26px' }} />}
              </button>
            </UserMenu>
          </div>
          <div className='clone-header' />
        </WrapperHeader>

        <ContentMenu>
          <div className={`content-menu ${open ? 'active' : ''}`}>
            <div className='logo-menu'>
              <Link to='/'>
                <img src='/logo.png' width={80} alt='' />
              </Link>
            </div>

            <div style={{ textAlign: 'center', marginBottom: 10 }}>
              <Button className='button-create-order' onClick={() => handelLink(ROUTER.orderCreate)} style={{ color: '#fff' }}>
                ĐƠN HÀNG MỚI
              </Button>
            </div>

            <Menu selectedKeys={[active]} defaultOpenKeys={['menu-swap']} mode='inline' theme='dark' inlineCollapsed={collapsed}>
              <Menu.Item key={ROUTER.adminHome} icon={<MenuOutlined />} onClick={() => handelLink(ROUTER.adminHome)}>
                MÀN HÌNH CHÍNH
              </Menu.Item>
              <Menu.Item key={ROUTER.orderTracking} icon={<SearchOutlined />} onClick={() => handelLink(ROUTER.orderTracking)}>
                ĐƠN ĐANG XỬ LÝ
              </Menu.Item>
              <Menu.Item
                key='/lich-su-dieu-chinh-khoi-luong'
                icon={<HistoryOutlined />}
                onClick={() => handelLink('/lich-su-dieu-chinh-khoi-luong')}
              >
                LỊCH SỬ ĐIỀU CHỈNH K/L
              </Menu.Item>
              <Menu.Item
                key='/don-dieu-chinh-khoi-luong'
                icon={<ReconciliationOutlined />}
                onClick={() => handelLink('/don-dieu-chinh-khoi-luong')}
              >
                ĐƠN ĐIỀU CHỈNH K/L
              </Menu.Item>
              <Menu.Item
                key='/ho-tro-khach-hang'
                icon={<QuestionCircleOutlined />}
                onClick={() => handelLink('/ho-tro-khach-hang')}
              >
                HỖ TRỢ KHÁCH HÀNG
              </Menu.Item>
              <Menu.Item key='/lich-su-don-van' icon={<CheckOutlined />} onClick={() => handelLink('/lich-su-don-van')}>
                ĐƠN VẬN HOÀN TẤT
              </Menu.Item>
              <Menu.Item key='/bao-cao-tai-chinh' icon={<BarChartOutlined />} onClick={() => handelLink('/bao-cao-tai-chinh')}>
                BÁO CÁO TÀI CHÍNH
              </Menu.Item>
              <Menu.Item key='/lich-su-ky-quy' icon={<FieldTimeOutlined />} onClick={() => handelLink('/lich-su-ky-quy')}>
                LỊCH SỬ KÝ QUỸ
              </Menu.Item>
              <Menu.Item key='/lich-su-doi-soat' icon={<NodeExpandOutlined />} onClick={() => handelLink('/lich-su-doi-soat')}>
                LỊCH SỬ ĐỐI SOÁT
              </Menu.Item>
            </Menu>
          </div>
        </ContentMenu>
      </>
    </WrapperHeaderPrivate>
  )
}

export default HeaderPrivate

const WrapperHeader = styled.div`
  position: relative;
  z-index: 10;
  padding-left: 250px;
  background-color: #056638;

  .content-header {
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 0;
    height: ${heightHeader};
    padding-left: 15px;
    padding-right: 15px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    gap: 12px;

    ${({ theme }) => theme.mediaQueries.md} {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      width: calc(100vw - 250px);
    }

    .wrap-search {
      display: flex;
      align-items: center;
      width: 100%;

      h4 {
        margin-right: 10px;

        ${({ theme }) => theme.mediaQueries.lg} {
          margin-right: 25px;
        }
      }

      button {
        &:not(:last-child) {
          margin-right: 10px;

          ${({ theme }) => theme.mediaQueries.lg} {
            margin-right: 25px;
          }
        }
      }

      .wrap-price {
        font-size: 1.5rem;
      }
    }

    .btn-open-menu {
      background: none;
      border: none;

      ${({ theme }) => theme.mediaQueries.lg} {
        display: none;
      }
    }
  }

  .clone-header {
    height: ${heightHeader};
  }
`

const ContentMenu = styled.div`
  .content-menu {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 250px;
    background-color: #056638;
    min-height: 100vh;
    height: 100%;
    overflow: auto;
    transform: translateX(-100%);
    transition: 0.25s;

    &.active {
      transform: translateX(0);
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      transform: translateX(0);
    }

    .logo-menu {
      text-align: center;
      padding: 10px 20px 20px 20px;

      img {
        background: #fff;
        border-radius: 50%;
      }
    }

    .ant-menu-inline > .ant-menu-item {
      height: 30px !important;
    }

    .ant-menu {
      background-color: #056638;

      .ant-menu-inline.ant-menu-sub {
        background-color: #056638;
      }

      &:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
          background-color: transparent;

          .ant-menu-title-content {
            font-weight: bold;
            color: #fff;
          }
        }
      }

      .ant-menu-title-content {
        color: #d3d1ff;
      }
    }

    .button-create-order {
      background: transparent;
      text-align: center;
      border: 1px solid #008848;
      width: calc(100% - 20px);

      &:hover {
        background: var(--button-primary-color);
        border-color: var(--button-primary-color);
      }
    }
  }
`

const UserMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  .user-icon {
    font-size: 16px;
  }
`

const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  min-width: 100px;
  cursor: pointer;

  a {
    display: block;
  }

  p {
    font-size: 12px;
    text-align: right;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    width: max-content;
  }
`
