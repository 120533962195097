const SWR_KEY = {
  ORDERS_REPORT_SUMMARY: '/orders/report-sumary',
  ORDERS_REPORT_COD: '/orders/report-cod',
  ESCROW_HISTORY: '/escrow-history/list',
  RECONCILATION_HISTORY: '/reconcilation/history',
  PUBLIC_SLIDER: '/slider/list',
  SETTINGS: '/setting/list',
  HISTORY_ORDER_UPDATE_WEIGHT: '/order-update-weights/list',
  ORDER_UPDATE_WEIGHT: '/orders/list-update-weights',
  CONFIG: '/public/config/list',
}

export default SWR_KEY

export const FAST_INTERVAL = 10000
export const MID_INTERVAL = 30000
export const SLOW_INTERVAL = 60000
