import 'assets/css/index.css'
import ScrollToTop from 'components/ScrollToTop'
import { getAuthToken } from 'helpers/FetchHelper'
import PrivateLayout from 'layouts/PrivateLayout'
import PublicLayout from 'layouts/PublicLayout'
import { Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Home from './Home'
import RoadmapPage from './Roadmap'
import AboutUsPage from './AboutUs'

export const isLogin = () => {
  const auth = getAuthToken()

  return Boolean(auth)
}

export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <PublicLayout {...rest}>
      <Component />
    </PublicLayout>
  )
}
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const navigate = useNavigate()

  // useLayoutEffect(() => {
  //   if (account) return
  //   navigate('/')
  // }, [account, navigate])

  // if (!account) return <></>

  return (
    <PrivateLayout {...rest}>
      <Component />
    </PrivateLayout>
  )
}

function App() {

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <ToastContainer theme='dark' />
        <Routes>
          <Route path='/' element={<PublicRoute component={Home} />} />
          <Route path='/roadmap' element={<PublicRoute component={RoadmapPage} />} />
          <Route path='/about-us' element={<PublicRoute component={AboutUsPage} />} />

          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
        <ScrollToTop />
      </BrowserRouter>
    </Suspense>
  )
}

export default App
