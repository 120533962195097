import { ConfigProvider, Layout } from 'antd'
import FooterPrivate from 'components/FooterPrivate'
import HeaderPrivate from 'components/HeaderPrivate'
import styled from 'styled-components'
import locale from 'antd/es/locale/vi_VN'

const StyleLayout = styled.div`
  position: relative;
  z-index: 0;
  max-width: 100%;

  .content {
    .content-page {
      padding-left: 0;
      padding-top: 15px;

      ${({ theme }) => theme.mediaQueries.lg} {
        padding-top: 30px;
        padding-left: 250px;
      }
    }
  }
`

const PrivateLayout = ({ children }) => {
  return (
    <Layout>
      <StyleLayout>
        <ConfigProvider locale={locale}>
          <HeaderPrivate />

          <div className='content'>
            <div className='content-page'>
              <div>{children}</div>
              <FooterPrivate />
            </div>
          </div>
        </ConfigProvider>
      </StyleLayout>
    </Layout>
  )
}

export default PrivateLayout
