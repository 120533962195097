import React from 'react'

const AboutUsPage = () => {
	return (
		<div className='flex flex-col lg:gap-20 gap-12 pb-24'>
			<div className="bg-cover bg-center bg-no-repeat xl:py-36 md:py-20 py-14" style={{ backgroundImage: "url(/images/backgrounds/about-us.png)" }}>
				<div className="flex flex-col justify-center lg:gap-6 gap-4 container">
					<h1 className="text-white font-extrabold lg:text-5xl md:text-4xl text-3xl">
						Investing in DeFi for
						<br />
						Everyone
					</h1>

					<p className="text-[#9aa9ff] lg:text-xl text-base max-w-[550px]">
						Our mission is to help everyone access & invest
						<br />
						in DeFi easier via a decentralized wallet
						<br />
						application that optimized user experience
					</p>
				</div>
			</div>

			<div className="flex flex-col gap-6 container">
				<p className="font-bold lg:text-4xl md:text-3xl text-2xl">Our Team</p>
				<p className="text-[#63656f] max-w-[700px] lg:text-xl text-base">
					An elite team consisting of programmers, growth hackers, product designers, and investors in building and investing blockchain products.
				</p>

				<div className='grid xl:grid-cols-3 md:grid-cols-2 lg:gap-10 gap-6'>
					<AboutCard />
					<AboutCard />
					<AboutCard />
					<AboutCard />
					<AboutCard />
					<AboutCard />
				</div>
			</div>
		</div>
	)
}

export default AboutUsPage

const AboutCard = () => (
	<div className="md:p-8 p-4 card-founding">
		<div className="mb-2 flex items-center justify-between">
			<div className="text-xl font-medium">
				Lorem Ipsum
			</div>
		</div>
		<div className="text-primary font-medium md:mb-10 mb-5 text-base">
			What is Lorem Ipsum?
		</div>
		<div className="font-medium mb-3 text-base">
			Neque porro quisquam est qui dolorem ipsum quia
		</div>
		<div className="text-[#63656f]">
			Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
		</div>
	</div>
)