import { Image, Tabs } from "antd";
import styled from "styled-components";

const DATA = [
	{
		label: "Explore",
		title: "Explore the entire DeFi market",
		desc: "An easy way to search and evaluate every single DeFi asset on the market.",
		image: "/images/home/feature-1.png"
	},
	{
		label: "Invest",
		title: "Invest in DeFi assets",
		desc: "Buy & sell DeFi assets at the best prices from selected liquidity pools.",
		image: "/images/home/feature-2.png"
	},
	{
		label: "Manage",
		title: "Manage Your Portfolio",
		desc: "Keep your portfolio in your pocket. Everything you need to manage your assets is available in a single app.",
		image: "/images/home/feature-3.png"
	},
]

const Features = () => {
	return (
		<div className='bg-primary pt-16 xl:pb-36 pb-16'>
			<div className="container">
				<TabsWrapper>
					<Tabs
						defaultActiveKey="Explore"
						items={DATA.map((item) => ({
							key: item.label,
							label: item.label,
							children: <TabItem data={item} />,
						}))}
					/>
				</TabsWrapper>
			</div>
		</div>
	)
}

export default Features

const TabItem = ({ data }) => (
	<div className="grid md:grid-cols-2 gap-10">
		<div className="flex justify-center md:justify-start">
			<Image preview={false} src={data.image} className="md:max-w-[550px] max-w-[300px]" />
		</div>
		<div className="flex flex-col justify-center lg:gap-6 gap-3">
			<p className="text-white font-extrabold lg:text-5xl md:text-3xl text-xl md:text-left text-center">
				{data.title}
			</p>

			<p className="text-[#ffffb3] lg:text-xl text-base md:text-left text-center">
				{data.desc}
			</p>
		</div>
	</div>
)

const TabsWrapper = styled.div`
	.ant-tabs-nav {
		&::before {
			border-color: #6083f4;
		}
	}

	.ant-tabs-nav-wrap {
		justify-content: center;
	}

	.ant-tabs-content-holder {
		margin-top: 30px;

		@media screen and (min-width: 768px) {
			margin-top: 60px;
		}
	}

	.ant-tabs-tab {
		color: #6083f4;
		font-size: 1.25rem;

		@media screen and (min-width: 768px) {
			font-size: 2rem;
		}

		&.ant-tabs-tab-active .ant-tabs-tab-btn {
			color: #fff !important;
		}
	}

	.ant-tabs-ink-bar {
		background: #fff;
	}
`