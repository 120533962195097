import { Image } from 'antd'

const DATA = [
	{
		label: "Farming",
		desc: "Stake your liquidity to earn interests, incentives,...",
		image: "/images/icons/farming.svg"
	},
	{
		label: "Saving",
		desc: "Earn interest by depositing your assets.",
		image: "/images/icons/saving.svg"
	},
	{
		label: "Borrowing",
		desc: "Access instant liquidity on the best protocols.",
		image: "/images/icons/borrowing.svg"
	},
	{
		label: "Pooling",
		desc: "Add liquidity to earn fees, incentives, voting rights.",
		image: "/images/icons/pooling.svg"
	},
]

const Earn = () => {
	return (
		<div className='bg-[#222428] pt-16 xl:pb-36 pb-16'>
			<div className='grid sm:grid-cols-2 sm:gap-0 gap-8 container lg:pt-24 sm:pt-14 pt-10'>
				<div className="flex flex-col justify-center lg:gap-10 gap-6">
					<h2 className="text-white font-extrabold lg:text-5xl md:text-4xl text-3xl">
						Earn & Borrow
					</h2>

					<div className="grid grid-cols-2 lg:gap-x-10 lg:gap-y-16 md:gap-6 gap-4">
						{
							DATA.map(item => <EarnItem data={item} key={item.label} />)
						}
					</div>
				</div>
				<div>
					<Image preview={false} src='/images/home/earn-section.png' className='max-h-[650px]' />
				</div>
			</div>
		</div>
	)
}

const EarnItem = ({ data }) => (
	<div className="flex flex-col gap-2">
		<div>
			<Image preview={false} src={data.image} className='max-h-[50px]' />
		</div>
		<p className="text-white font-bold lg:text-3xl text-xl">
			{data.label}
		</p>
		<p className="text-[#9ea0a4] md:text-base text-sm">
			{data.desc}
		</p>
	</div>
)

export default Earn